<template>
    <v-container fluid>
        <v-row row wrap>
            <v-col cols="12">
                <v-card raised>
                <v-data-table
                    :headers="headers"
                    hide-headers
                    :items="items"
                    hide-default-footer
                    item-key="id"
                    no-data-text="No Orders"
                    :expanded.sync="expanded"
                    show-expand
                >
                <template v-slot:[`item.payment.amount`]="{ item }">
                    <span>{{ (item.payment.amount/100.0).toLocaleString("en-US", {style:"currency", currency:"USD"}) }}</span>
                </template>
                <template v-slot:expanded-item="{ item }">
                    <td :colspan="headers.length">
                        <EditOrder 
                        :order=item 
                        :member="member"
                        item-key="id"
                        />
                    </td>
                </template>
            </v-data-table>
            </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapMutations, mapGetters} from 'vuex'
import moment from 'moment-timezone'
import EditOrder from './EditOrder'
export default {
    data () {
        return {
            search: '',
            headers: [
                { text: 'Order', align: 'left', sortable: false, value: 'name' },
                { text: 'Variation', align: 'left', sortable: false, value: 'line_items[0].variation_name' },
                { text: 'Qty', align: 'center', sortable: false, value: 'line_items[0].quantity' },
                { text: 'Total', align: 'center', sortable: false, value: 'payment.amount'},
                { text: 'Status', align: 'right', sortable: false, value: 'status' },
                { text: '', value: 'data-table-expand' }
            ],
            selected: ''
        }
    },
    props: ['items', 'member'],
    components: {
        EditOrder
    },
    computed: {
        ...mapGetters ([
            'loading'
        ]),
    },
    methods: {
        calendar (dateStr) {
            return moment(dateStr, "YYYY-MM-DD HH:mm").calendar()
        }
    }
}
</script>